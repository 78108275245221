












































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import { PageMode } from '@/utils/type'
import {
    apiBargainActivityDelete,
    apiBargainActivityStop,
    apiBargainActivityConfirm
} from '@/api/marketing/bargain.ts'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class BargainPane extends Vue {
    $refs!: {
        valueRef: any
    }
    @Prop() value: any
    @Prop() pager!: any
    status = true

    // 新增
    toAdd() {
        this.$router.push({
            path: '/bargain/bargain_edit',
            query: {
                mode: PageMode.ADD
            }
        })
    }

    // 详情
    toDetails(item: any) {
        this.$router.push({
            path: '/bargain/bargain_edit',
            query: {
                mode: PageMode.EDIT,
                id: item.id,
                status: '0'
            }
        })
    }

    // 编辑
    toEdit(item: any) {
        this.$router.push({
            path: '/bargain/bargain_edit',
            query: {
                mode: PageMode.EDIT,
                id: item.id,
                status: item.status
            }
        })
    }

    // 数据详情
    toData(item: any) {
        // this.$router.push({
        // 	// path: "/bargain/bargain_edit",
        // 	query: {
        // 		id: item.id,
        // 	},
        // });
    }

    // 删除
    onDelete(id: any) {
        apiBargainActivityDelete({
            id: id
        }).then((res: any) => {
            this.$emit('refresh')
        })
    }

    // 结束
    onStop(id: number) {
        apiBargainActivityStop({
            id: id
        }).then((res: any) => {
            this.$emit('refresh')
        })
    }

    // 确定
    onConfirm(id: number) {
        apiBargainActivityConfirm({
            id: id
        }).then((res: any) => {
            this.$emit('refresh')
        })
    }
}










































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiBargainActivityLists } from '@/api/marketing/bargain'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import BargainPane from '@/components/marketing/bargain/bargain-pane.vue'
@Component({
    components: {
        LsPagination,
        BargainPane
    }
})
export default class BargainLists extends Vue {
    /** S Data **/
    // 日期选择器
    pickerOptions = {
        shortcuts: [
            {
                text: '最近一周',
                onClick(picker: any) {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                    picker.$emit('pick', [start, end])
                }
            },
            {
                text: '最近一个月',
                onClick(picker: any) {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                    picker.$emit('pick', [start, end])
                }
            },
            {
                text: '最近三个月',
                onClick(picker: any) {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                    picker.$emit('pick', [start, end])
                }
            }
        ]
    }
    tableData = []
    // 顶部查询表单
    pager: RequestPaging = new RequestPaging()
    // 顶部查询表单
    form = {
        status: ' ', // 砍价活动状态
        start_time: '',
        end_time: '',
        activity_info: '', // 砍价活动
        goods_info: '' // 商品信息
    }
    // 各状态数量
    tabCount = {
        all: 0,
        end: 0,
        ing: 0,
        wait: 0
    }
    /** E Data **/

    /** S Methods **/
    splitTime() {
        if (this.tableData != null) {
            this.form.start_time = this.tableData[0]
            this.form.end_time = this.tableData[1]
        }
    }
    // 重置
    onReset() {
        this.tableData = []
        this.form.status = ' '
        this.form.start_time = ''
        this.form.end_time = ''
        this.form.activity_info = '' // 砍价活动
        this.form.goods_info = '' // 商品信息

        this.getList()
    }
    // 提现列表
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiBargainActivityLists,
                params: {
                    ...this.form
                }
            })
            .then((res: any) => {
                //
                this.tabCount = res?.extend
            })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getList()
    }
    /** E Life Cycle **/
}
